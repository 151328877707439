export const environment = {
  production: true,
  edgeService: 'https://api.int.gms.ceres.siemens.cloud',
  scd_url: 'https://api.dev.scd.suites.siemens.cloud',
  languageAssetsPath: './assets/language/',
  siteCollection: '/test/10000120',
  tracking: false,
  whitelist: ['api.int.gms.ceres.siemens.cloud'],
  authentication: {
    unauthorizedRoute: '/unauthorized',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: 'tj1ij0fdg9t2pe1k7qdjvb3ib',
    authority:
      'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg',
    authWellknownEndpointUrl:
      'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg/.well-known/openid-configuration',
    scope: 'openid profile email',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    autoUserInfo: true,
    renewTimeBeforeTokenExpiresInSeconds: 300,
    renewUserInfoAfterTokenRenew: true,
    ignoreNonceAfterRefresh: true
  },
  apiKey: 'CSNzjWXd4j71NClookSIc1WjWYB5BlPD15674OEr',
  tenant: 'INT - GMS',
  guestAccess: false,
  links: {
    documentOfferTemplate:
      'https://scf.siemens.cloud/document/link/66454bfd-fc59-41e1-872d-58e0b85b6c8d',
    documentOfferTemplateEN:
      'https://scf.siemens.cloud/document/link/deaa7a0e-2640-4bb9-a66c-04ccb519f3fd'
  },
  junoApp: 'https://juno.suites.siemens.cloud',
  junoApiURI: 'https://api.mail.wcs-apps.siemens.cloud/v2.0',
  junoAppId: '73a1c4a89ae54505824b6f0f4fcc78b3'
};
